
import { Options, Vue } from 'vue-class-component';
import { add, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, isThisMonth, getDay, isFuture, isSameDay, isSameMonth } from 'date-fns';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays/index';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { InjectReactive, Prop, Watch } from 'vue-property-decorator';
import isToday from 'date-fns/isToday';
import { DayOfTheMonth } from '@/common/models/DayOfTheMonth.model';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';

@Options({
	name: 'MonthAvailability',
	components: {},
	emits: ['onSelect'],
})
export default class MonthAvailability extends Vue {
	@Prop() selectedDate!: Date | null;
	@InjectReactive() activity!: OrderActivity;

	date!: Date;
	monthName!: string;

	loadingMonth = false;

	calendar: {
		date: Date;
		number: string;
		inMonth: boolean;
		soldOut: boolean;
		available: boolean;
		closed: boolean;
		selected: boolean;
		disabled: boolean;
		price: number | null;
	}[] = [];

	weekdays: string[] = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

	get isLargeGroup() {
		return orderModule.isLargeGroup;
	}

	created() {
		this.date = this.selectedDate ? this.selectedDate : new Date();

		this.setMonth(0);
	}
	@Watch('selectedDate')
	onPropertyChanged(value: Date, oldValue: Date) {
		this.date = value;
		this.createCalendar(this.selectedDate ?? new Date());
	}

	createCalendar(d: Date) {
		this.calendar = [];
		const monthStart = startOfMonth(d);
		const monthEnd = endOfMonth(d);
		const calStart = startOfWeek(monthStart, { weekStartsOn: 0 });
		const calEnd = endOfWeek(monthEnd, { weekStartsOn: 0 });

		for (let i = 0; i <= differenceInCalendarDays(calEnd, calStart); i++) {
			const day = add(calStart, { days: i });
			this.calendar.push({
				number: format(day, 'd'),
				inMonth: isSameMonth(this.date, day),
				soldOut: false,
				available: false,
				closed: false,
				date: day,
				disabled: !isToday(day) && !isFuture(day),
				selected: !!this.selectedDate && isSameDay(day, this.selectedDate),
				price: null,
			});
		}

		this.mapAvailability(monthStart);
	}

	setMonth(dir: number) {
		console.log('setmonth');
		this.date = add(this.date, { months: dir });
		this.monthName = format(this.date, 'MMMM');
		this.createCalendar(this.date);
	}

	async mapAvailability(d: Date) {
		// maps availability properties to calendar days
		const mapMonthData = (d: Date, availability: DayOfTheMonth[]) => {
			const availStartDay = getDay(d); // int of day of week
			for (let i = 0; i <= availability.length; i++) {
				const idx = i + availStartDay;

				if (availability[i]) {
					this.calendar[idx].soldOut = availability[i].SoldOut;
					this.calendar[idx].available = availability[i].Available;
					this.calendar[idx].closed = availability[i].Closed;
					this.calendar[idx].disabled = this.calendar[idx].disabled || availability[i].Closed || availability[i].SoldOut;
					this.calendar[idx].price = availability[i].PriceValue;
				}
			}
		};

		// check if month availability cached
		if (this.activity.monthAvailability.has(format(d, 'MMMM-dd-yyyy'))) {
			mapMonthData(d, this.activity.monthAvailability.get(format(d, 'MMMM-dd-yyyy'))!);
		} else {
			this.loadingMonth = true;
			this.activity
				.getMonthAvailability(d)
				.then((res) => {
					if (res) mapMonthData(d, this.activity.monthAvailability.get(format(d, 'MMMM-dd-yyyy'))!);
				})
				.finally(() => (this.loadingMonth = false));
		}
	}
}
